.menuitem {
  display: flex;
  align-items: center;
}

.circle {
  display: block;
  margin-right: 8px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}
