@import "src/styles/colors";

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  background: $white;
  transform: translate(-50%, -50%);
  max-width: 1200px;
  max-height: 95vh;
  border-radius: 8px;
  overflow: auto;
}

button.close_btn {
  position: absolute;
  right: 40px;
  top: 32px;
  z-index: 10;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgb(0 0 0 / 50%);
  z-index: 5;
}
