.wrapper {
  display: flex;
  height: 95vh;
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  width: 350px;
}

.btn {
  margin-top: 40px;
  margin-bottom: 10px;
  width: 100%;

  &:last-child {
    margin-top: 0;
  }
}

.error_message {
  font-size: 1rem;
}
