.flex_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 24px 0;

  & > div:not(:last-child) {
    margin-right: 32px;
  }
}

.search {
  position: relative;
  background: white;

  input {
    padding-left: 50px;
  }

  & > &__btn {
    position: absolute;
    left: 3px;
  }
}

.filter {
  display: flex;
  align-items: center;

  &__text {
    margin-left: 8px;
    text-transform: uppercase;
    font-size: 0.8rem;
  }
}

.export_btn {
  text-align: right;
  flex-grow: 2;
}

.tab_wrapper {
  margin-bottom: 24px;
}
