@import "src/styles/colors";

.view {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  .btn_group {
    position: absolute;
    left: 50%;
    top: 16px;
    transform: translateX(-50%);
    background: $white;
    z-index: 5;
  }

  img {
    margin: auto;
    max-width: 100%;
    max-height: 60vh;
  }
}

.image_replacement {
  display: flex;
  background: $bg-gray;
  height: 100%;
  width: 100%;

  .photo_wrap {
    margin: auto;

    p {
      font-size: rem(14);
      color: $text-gray;
      text-align: center;
      margin: 8px;
      max-width: 200px;
    }

    .bold {
      font-weight: bold;
    }
  }

  .photo {
    display: flex;
    margin: auto;
    width: 220px;
    height: 220px;
    border-radius: 50%;
    background: $white;

    svg {
      margin: auto;
      width: 120px;
      height: 120px;
      color: $bg-gray-lighten;
    }
  }
}
