@import "src/styles/colors";

.sidebar {
  position: relative;
  min-width: 200px;

  & .back {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-55%);
    color: #b0b0b0;

    &:hover {
      color: #311b92;
    }
  }
}

.new_request {
  display: flex;
  height: 68px;
  margin-bottom: 20px;
  border-bottom: 1px solid $bg-gray-lighten;

  & button {
    margin: auto;

    &:hover {
      color: #311b92;
    }
  }
}

.menu {
  li,
  a {
    display: block;
  }

  .link {
    display: flex;
    align-items: center;
    padding: 10px;
    padding-left: 15px;
    text-transform: capitalize;
    color: #b0b0b0;
    border-left: 5px solid transparent;

    &:hover {
      color: #311b92;
    }

    span {
      margin-left: 8px;
    }
  }

  .active_link {
    color: #3949ab;
    border-left: 5px solid #3949ab;
    background: $bg-gray;
  }
}

.sidebar_close {
  min-width: 65px;
}
