@import "src/styles/helpers";
@import "src/styles/colors";

.page {
  flex-grow: 2;
  min-height: calc(100vh - 98px);
  background: $bg-gray;
  padding: 0 46px;
  position: relative;

  &_center {
    margin: auto;

    &_v {
      margin: auto 0;
    }

    &_h {
      margin: 0 auto;
    }
  }
}

.page_title {
  font-size: rem(34);
  margin: 24px 0;
  text-transform: capitalize;
}

.page_content {
  overflow-x: auto;
}

.wrapper {
  display: flex;
  overflow: auto;
}
