@import "src/styles/colors";

.page {
  &_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  &_top {
    position: absolute;
    top: 0;
    pointer-events: none;
    z-index: 1;
  }

  &_bottom {
    position: absolute;
    bottom: 0;
    pointer-events: none;
    width: 100%;
  }

  &_wrapper,
  &_top,
  &_bottom {
    & > :not(.page_top):not(.page_bottom) {
      pointer-events: all;
    }
  }

  &_filters {
    background: $white;
    padding: 16px;
    display: inline-block;
  }

  &_filter {
    display: flex;
    align-items: center;
  }

  &_filter_color {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 4px;
    background: $text-violet;
  }

  &_filter_title {
    padding: 0 4px;
  }

  &_filter_header {
    h5 {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  &_filter_subheader {
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin-bottom: 20px;
  }

  &_reset_filter {
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;
    color: $text-violet;
    background: transparent;
    border: none;
  }

  &_marker_count_wrapper {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    pointer-events: none !important; /* stylelint-disable-line */
  }

  &_marker_count {
    background: $white;
    text-align: center;
    display: inline-block;
    padding: 8px 16px;
  }

  &_search_input {
    display: inline-block;
    width: 290px;
    margin: 25px;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
    background: $white;
    position: relative;
    border-radius: 4px;
    border: none;

    .search_icon {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 5px;
      left: 10px;
    }

    input {
      width: 100%;
      padding: 10px 10px 8px 46px;
      border: none;
      font-size: 14px;
      outline: none;
    }
  }
}

.damage_info {
  padding: 24px 16px 24px 8px;
  font-size: 14px;
  font-weight: bold;
  color: $text-gray;
}
