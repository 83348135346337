@import "src/styles/helpers";
@import "src/styles/colors";

$text-color: #ffffff;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 30px;
  background: #10054d;
  color: $text-color;

  button {
    color: $text-color;
  }
}

.title {
  margin-left: 16px;

  & > span {
    display: block;
    font-size: rem(20);
    font-weight: bold;

    &:last-child {
      padding-top: 6px;
      font-size: rem(14);
      font-weight: normal;
    }
  }
}

%flex {
  display: flex;
  align-items: center;
}

.flex {
  @extend %flex;
}

.profile {
  @extend %flex;
  margin-left: 20px;

  &_gray {
    color: $text-gray;
  }
}

.region {
  margin-left: 20px;
  font-size: rem(28);
  text-decoration: underline;
}

button.exit {
  justify-content: flex-start;
  color: $error;
}
