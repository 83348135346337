.wrapper {
  display: flex;
  min-height: 95vh;
  padding: 12px 0;
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  width: 350px;
}

.btn {
  width: 100%;
  margin-top: 40px;
}

.input {
  width: 100%;
}

.error_message {
  margin-top: 16px;
  text-align: center;
  font-size: 1rem;

  &::first-letter {
    text-transform: uppercase;
  }
}
