@import "src/styles/colors";

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 28px 48px;
  border-top: 1px solid $bg-gray-lighten;

  button {
    margin-left: 30px;
  }
}
