/* stylelint-disable selector-class-pattern, declaration-no-important */
@import "src/styles/colors";

.info_map {
  &_window {
    position: relative;

    img {
      max-width: 296px;
      max-height: 296px;
      object-fit: contain;
      object-position: center;
      margin: 16px 0;
    }
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 32px 0 16px;

    & > * {
      cursor: pointer;
    }
  }

  &_edit_btn {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_window_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding: 0 16px;

    b {
      font-size: 20px;
      padding-right: 10px;
    }

    div {
      font-size: 15px;
    }
  }

  &_object_status {
    padding: 6px 12px;
    border-radius: 16px;
    border: 1px solid $black;
  }

  &_no_photo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    text-align: center;
  }
}

.gm-style-iw button.gm-ui-hover-effect {
  top: 9px !important;
  right: 10px !important;

  span {
    width: 28px !important;
    height: 28px !important;
  }
}

.custom_google_map {
  * {
    outline: none;
  }

  .gm-style-iw-d {
    padding: 0;
    overflow: auto !important;
  }

  .gm-style .gm-style-iw-c {
    padding: 0;
  }

  .custom_marker_tooltip {
    position: relative;
    z-index: 5;
    padding: 10px 4.8px;
    padding-bottom: 10px;
    background: $white;
  }
}
