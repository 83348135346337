.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: loop 1s linear infinite;
  color: #ffffff;
}

@keyframes loop {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
