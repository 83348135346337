@import "src/styles/helpers";
@import "src/styles/colors";

.header {
  display: flex;
  align-items: center;
  font-size: rem(34);
  padding: 32px 40px;
  color: $text-gray;

  .title {
    margin-right: 16px;
  }
}

button.close_btn {
  position: absolute;
  right: 40px;
  top: 32px;
}

.modal_main {
  display: flex;
  padding: 0 40px;
  width: 98vw;
  max-width: 1200px;
}

.description {
  width: 40%;
  color: $text-dark;
  max-height: 65vh;
  overflow-y: auto;
  padding-right: 15px;

  &_item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  &_icon {
    margin-right: 8px;
    color: $text-gray;
  }

  .desc_text {
    margin: 5px 0;
  }
}

.view_wrapper {
  width: 60%;
}
