@import "src/styles/helpers";
@import "src/styles/colors";

$input-width: 350px;

.form_wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 0 40px;
}

.header {
  padding: 32px 40px;
  font-size: 2.125rem;
  color: $text-gray;
}

.form {
  width: 98vw;
  max-height: 95vh;
  max-width: 100%;
}

.form_block {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding-right: 15px;
}

.submit_wrapper {
  width: 100%;
  display: flex;
  margin-top: 20px;

  .btn {
    margin: auto;
    width: 100%;
    max-width: $input-width;
  }
}

button.file_btn {
  width: auto;
  justify-content: flex-start;
}

div.input {
  margin-bottom: 22px;
  margin-right: 10px;
  width: 100%;
  max-width: $input-width;
}

.map_wrapper {
  width: 60%;
  min-height: 600px;
  position: relative;

  div {
    cursor: crosshair;
  }
}

.file_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  input {
    display: none;
  }
}
